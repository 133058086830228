import './App.css';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import LoginLayoutRoute from './layouts/loginLayout';
import UserPage from './pages/UserPage';
import DataFilterPage from './pages/DataFilterPage';
import AdminLayoutRoute from './layouts/adminLayout';
import HistoryReportPage from './pages/HistoryReportPage';
function App() {
  return (
    <Router>
      <Switch>
        <LoginLayoutRoute path="/" exact component={LoginPage}></LoginLayoutRoute>
        <LoginLayoutRoute path="/login" exact component={LoginPage}></LoginLayoutRoute>
        <AdminLayoutRoute path="/home" exact component={DashboardPage}></AdminLayoutRoute>
        <AdminLayoutRoute path="/user" exact component={UserPage}></AdminLayoutRoute>
        <AdminLayoutRoute path="/filter" exact component={DataFilterPage}></AdminLayoutRoute> 
        <AdminLayoutRoute path="/report" exact component={HistoryReportPage}></AdminLayoutRoute> 
      </Switch>
    </Router>
  );
}

export default App;
