import React from 'react'
import { FaCity,FaJediOrder, FaInstalod, FaUser, FaQuestionCircle, FaFilter, FaHistory, FaUserAstronaut, FaGooglePlusG } from 'react-icons/fa'
import { SiGooglemeet} from 'react-icons/si'
import { Link } from 'react-router-dom'
import { store } from '../../store/store';

export default function LeftMenuComponent() {

    const userInfo = store.getState();

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
            {/* <a className="sidebar-brand d-flex align-items-center justify-content-center" href={userInfo.userData.User.Role_Name === 'Admin' ? '/home' : 'home'}> */}
            <Link to= '/home'>
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                </div>
                <img src= "hicare-logo.png" style={{ height: "80px", width: "180px", marginTop: "10px", marginLeft: "10px" }} alt="HiCare" ></img>
            </Link>
            {/* </a> */}
            <div className="sidebar-heading">

            </div>
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
               
                        <li className="nav-item">
                            <Link to='/filter' className="nav-link collapsed"><FaFilter></FaFilter>{' '} Scheduler Event</Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/report' className="nav-link collapsed"><FaInstalod></FaInstalod>{' '} Event History Report</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link to='/user' className="nav-link collapsed"><FaUser></FaUser>{' '} User</Link>
                        </li> */}
                    
            </ul>

        </ul>

    )
}
