import React, { useState } from 'react'
import { store } from '../../store/store';
import { FaSignInAlt, FaCogs } from "react-icons/fa";
import { thunk_reset_user_info_creator } from '../../actions/fetchAction';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { MenuContext } from './MenuContext';
import PopupComponent from '../Common/PopupComponent';
import UserCategoryMenu from './UserCategoryMenu';

function TopMenuComponent(props) {
    const userInfo = store.getState();
    const history = useHistory();
    const [isModelOpen, setModel] = useState(false);

    const toggle = () => setModel(!isModelOpen);
    const Logout = () => {
        props.dispatch(thunk_reset_user_info_creator({}));
        history.push('/');
    }

    return (
        <MenuContext.Provider value={{ userInfo, isModelOpen, toggle }}>
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow d-sm-none">

                    </li>
                    <li className="nav-item dropdown no-arrow mx-1">

                    </li>
                    <li className="nav-item dropdown no-arrow mx-1">

                    </li>
                    <div className="topbar-divider d-none d-sm-block"></div>

                    <li className="nav-item dropdown no-arrow">
                    <Dropdown style={{ marginTop: '10px' }}>
                        <Dropdown.Toggle variant="" id="dropdown-basic">
                            {userInfo.userData.User.First_Name} {userInfo.userData.User.Last_Name} &nbsp;&nbsp;<FaCogs></FaCogs>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={() => Logout()}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                        <PopupComponent title="Update Category" PopupFor="Menu">

                        </PopupComponent>
                    </li>
                </ul>
            </nav >
        </MenuContext.Provider>
    )
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

export default connect(mapStateToProps)(TopMenuComponent);