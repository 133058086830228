
import { MenuContext } from "../components/Menu/MenuContext";
import { UserContext } from "../components/User/UserContext";
import { DataFilterContext } from "../components/DataFilter/DataFilterContext";

export const ContextFactory = (type) => {
    switch (type) {
        case "User":
            return UserContext;
        case "Menu":
            return MenuContext;
        case "DataEvent":
            return DataFilterContext;
        default:
            return null;
    }
}