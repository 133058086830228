import React, { useContext, createContext } from 'react'
import { Modal } from 'react-bootstrap';
import { ContextFactory } from '../../utils/ContextFactory';
import { UserContext } from "../User/UserContext";
import { FaTimes } from 'react-icons/fa';

function PopupComponent(props) {
    const { isModelOpen, toggle } = useContext(ContextFactory(props.PopupFor));

    return (
        <Modal show={isModelOpen} onHide={toggle} size={props.Size ? props.Size : "md"} backdrop="static">
            <Modal.Header>
                <b>{props.title}</b>
                <FaTimes onClick={() => toggle()} />
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
        </Modal>
    )
}

export default PopupComponent
