import { faTemperatureLow } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Button, FormGroup, Label, Input, Alert } from "reactstrap";
import { thunk_user_info_creator } from "../../actions/fetchAction";
import * as LoginService from "../../services/LoginService";
import { Toaster, toast } from "react-hot-toast";

function LoginComponent(props) {
  const [error, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur", // "onChange"
  });
  const history = useHistory();
  const [disable, setDisable] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [disableOTP, setDisableOTP] = useState(false);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    let interval;
    if (otpSent && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            return 60; // Reset timer after 1 minute
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else {
      clearInterval(interval); // Clear the interval if OTP is not sent or component unmounts
    }

    return () => clearInterval(interval); // Cleanup on unmount
  }, [otpSent, timer]);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };

  const sendOTP = () => {
    if (phoneNumber != "" && phoneNumber != undefined && phoneNumber != null) {
      setDisableOTP(true);
      let response = LoginService.GetOTPByMobileNo(phoneNumber).then((data) => {
        if (data.data.IsSuccess) {
          toast.success(data.data.Data);
          setOtpSent(true);
          setTimeout(() => {
            setDisableOTP(false);
            setTimer(60); // Reset timer
            setOtpSent(false);
          }, 60000); // Disable for 1 minute (60000 milliseconds)
        } else {
          toast.error(data.data.Data);
          setDisableOTP(false);
        }
      });
    } else {
      toast.error("Mobile Number is required!");
    }
  };

  //   const formSubmit = (value) => {
  //     setErrorMessage("");
  //     setDisable(true);
  //     const user = { Email_Id: value.userName, Password: value.password };
  //     let result = LoginService.ValidateUser(user)
  //       .then((data) => {
  //         if (data.data.IsSuccess) {
  //           props.dispatch(thunk_user_info_creator(data.data));
  //           toast.success("Login Successful");
  //           setTimeout(() => {
  //             history.push("/home");
  //           }, 1000);
  //         } else {
  //           setDisable(false);
  //           toast.error("Invalid User");
  //           setErrorMessage("Invalid User");
  //         }
  //       })
  //       .catch((error) => {
  //         setDisable(false);
  //         setErrorMessage(error.message);
  //         toast.error(error.message);
  //       });
  //   };

  const handleLoginSubmit = () => {
    if (otp != "" && otp != undefined && otp != null) {
      setErrorMessage("");
      setDisable(true);
      //const user = { Email_Id: value.userName, Password: value.password };
      const user = { Mobile_No: phoneNumber, Login_OTP: otp };
      let result = LoginService.ValidateUser(user)
        .then((data) => {
          if (data.data.IsSuccess) {
            props.dispatch(thunk_user_info_creator(data.data));
            toast.success("Login Successful");
            setTimeout(() => {
              history.push("/home");
            }, 1000);
          } else {
            setDisable(false);
            toast.error(data.data.Data);
            setErrorMessage(data.data.Data);
          }
        })
        .catch((error) => {
          setDisable(false);
          setErrorMessage(error.message);
          toast.error(error.message);
        });
    } else {
      toast.error("Please enter your OTP!");
    }
  };

  return (
    <div className="p-5">
      <div className="text-center">
        <span
          className="h1 fw-bold mb-0"
          style={{ color: "#00bc7a" }}>
          {" "}
          HiCare Report Scheduler
        </span>
      </div>
      <Toaster
                position="top-center"
                reverseOrder={false}
            />
      {!otpSent ? (
        <>
          <div className="form-outline mb-4">
            <label className="form-label requiredfeild">Mobile Number</label>
            <input
              type="number"
              id="form2Example17"
              className="form-control form-control-lg"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
          </div>
          <div className="pt-1 mb-4">
            <button
              className="btn btn-success btn-lg btn-block"
              type="button"
              disabled={disableOTP}
              onClick={sendOTP}>
              Get OTP
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="form-outline mb-4">
            <label className="form-label requiredfeild"> OTP</label>
            <input
              type="number"
              id="form2Example27"
              className="form-control form-control-lg"
              value={otp}
              onChange={handleOTPChange}
            />
          </div>
          <p>Resend OTP in: {timer} seconds</p>
          <div className="pt-1 mb-4">
            <button
              className="btn btn-success btn-lg btn-block"
              type="button"
              disabled={disable}
              onClick={handleLoginSubmit}>
              Login
            </button>
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

export default connect(mapStateToProps)(LoginComponent);
