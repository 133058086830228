// export const API_DOMAIN = "http://localhost:3933/api/";
export const API_DOMAIN = "https://dashboardapi.hicare.in/reportdesk/api/";
export const API_HEADER = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
};

export const Event_Type = [
    { Key: "API", Value: "API" },
    { Key: "Database", Value: "Database" }
];

export const API_Event_Method = [
    { Key: "GET", Value: "GET" },
    { Key: "POST", Value: "POST" },
    { Key: "PUT", Value: "PUT" },
    { Key: "DELETE", Value: "DELETE" }
];

export const DB_Event_Method = [
    { Key: "Store Procedure", Value: "Store Procedure" },
    { Key: "Query", Value: "Query" }
];

export const DataType = [
    { Type: "Boolean", Type: "Boolean" },
    { Type: "Datetime", Type: "Datetime" },
    { Type: "Double", Type: "Double" },
    { Type: "Float", Type: "Float" },
    { Type: "Integer", Type: "Integer" },
    { Type: "Numeric", Type: "Numeric" },
    { Type: "String", Type: "String" }
];

export const Scheduled_Type = [
    { Key: "Daily", Value: "Daily" },
    { Key: "Every Minute", Value: "Every Minute" },
    { Key: "Every Hour", Value: "Every Hour" },
    { Key: "Custom Expression", Value: "Custom Expression" }
];
