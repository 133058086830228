import React, { createContext, useContext, useState, useEffect } from 'react'
import { store } from '../../store/store';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom'
import { LineChart, ResponsiveContainer, Legend, Tooltip, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

export default function DashboardComponent(props) {
    const userInfo = store.getState();
    const [DashboardChartData, setDashboardDailyReport] = useState([]);
    const [error, setError] = useState("");
    const history = useHistory();

    useEffect(() => {
    }, [])

  

    return (
        <div className="container-fluid">

            <img src="Dashboard.jpg" style={{ width: "100%", height: "60%" }} alt="HiCare" />
            
        </div>
    )
}
