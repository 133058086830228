import React from 'react'
import { BrowserRouter as Route, Router, Link } from 'react-router-dom'
import LoginComponent from '../components/Login/LoginComponent';
import loginLayout from '../layouts/loginLayout';

const layouts = {
    'parentLayout': loginLayout,
};

export default function LoginPage() {
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-8">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-5 d-none d-lg-block" style={{textAlign:'center',verticalAlign:'middle'}}>
                                    <img style={{height:"420px"}}src="https://s3.ap-south-1.amazonaws.com/hicare-others/663b7da9-4a94-4375-aad1-64381f64491c.png"></img>
                                </div>
                                <div className="col-lg-7">
                                    <LoginComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
