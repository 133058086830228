const initialdata = {
    userData: {},
    isAuthenticated: false
}
const asyncReducer = (state = initialdata, action) => {
    switch (action.type) {
        case "user_info":
            return Object.assign({}, state, {
                userData: action.user,
                isAuthenticated: true
            });
        case "logout":
            return initialdata;
        default:
            return state;
    }
}
export default asyncReducer;