import React from 'react'
import SchedulerHistoryReportList from '../components/SchedulerHistoryReport/SchedulerHistoryReportList'

function HistoryReportPage() {
    return (
        <SchedulerHistoryReportList></SchedulerHistoryReportList>
    )
}

export default HistoryReportPage
