import { Button, Table, Alert } from 'reactstrap';
import React, { useState, useEffect } from 'react'
import PopupComponent from '../Common/PopupComponent';
import UserDetailComponent from './UserDetailComponent';
import { FaCheck, FaCheckCircle, FaTimesCircle, FaCross, FaEdit, FaPlus, FaRegTrashAlt, FaUser } from 'react-icons/fa';
import { UserContext } from './UserContext';
import { store } from '../../store/store';
import * as userservice from '../../services/UserService';
import ContainerComponent from '../Common/ContainerComponent';
import LoadingSpinnerComponent from '../../utils/LoadingSpinnerComponent';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom'

function UserListComponent() {
    const [isModelOpen, setModel] = useState(false);
    const [userList, setUserList] = useState([]);
    const [selectedUserId, setUserId] = useState(0);
    const [error, setError] = useState('');
    const userInfo = store.getState();
    const [editMode, SetEditMode] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!isModelOpen) {
            GetAllUserList();
        }
    }, [isModelOpen])

    const toggle = () => {
        setModel(!isModelOpen)
    };


    const editUser = (userId) => {
        SetEditMode(true);
        setUserId(userId);
        toggle();
    }

    const GetAllUserList = () => {
        setError('');
        userservice.GetAllUserList().then(data => {
            if (data.data.IsSuccess) {
                setUserList(data.data.ResponseData);
            } else {
                setError(data.data.errorMessage);
                toast.error(data.data.errorMessage);
            }
        }).catch(error => {
            setError(error.message);
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else
            {setError(error.message);
            toast.error(error.message);}
        });
    };


    const ActivateDeactiveUser = (User_Id, IsActive) => {
        setError('');
        userservice.ActivateDeactiveUser(User_Id, !IsActive).then(data => {
            if (data.data.IsSuccess) {
                toast.success(data.data.UiMessage)
                GetAllUserList();
            } else {
                setError(data.data.errorMessage);
                toast.error(data.data.errorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else
            {setError(error.message);
            toast.error(error.message);}
        });
    }
    const addNewUser = () => {
        SetEditMode(false);
        setUserId(0);
        toggle();
    }

    return (
        <UserContext.Provider value={{ isModelOpen, selectedUserId, toggle }}>
            <ContainerComponent title="User" Icon={<FaUser/>} ContextType="User" AddNewAction={addNewUser}>
                {error ? (<Alert color="danger">
                    {error}
                </Alert>) : <></>}
                <LoadingSpinnerComponent></LoadingSpinnerComponent>
                 <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <Table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Role Name</th>
                            <th>Email Id</th>
                            <th>Mobile No</th>
                            <th>Active</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userList.map((user) => {
                                return (
                                    <tr key={user.User_Id}>
                                        <td>{user.User_Id}</td>
                                        <td>{user.First_Name} {user.Last_Name}</td>
                                        <td>{user.Role_Name}</td>
                                        <td>{user.Email_Id}</td>
                                        <td>{user.Mobile_No}</td>
                                        <td>
                                            {
                                                user.IsActive ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />
                                            }
                                        </td>
                                        <td>
                                            <FaEdit onClick={
                                                () => { editUser(user.User_Id) }
                                            }></FaEdit>{' '}
                                            {
                                                user.IsActive ? <FaRegTrashAlt style={{ color: 'green' }} onClick={() => { ActivateDeactiveUser(user.User_Id, user.IsActive) }} /> : <FaRegTrashAlt style={{ color: 'red' }} onClick={() => { ActivateDeactiveUser(user.User_Id, user.IsActive) }} />
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {
                            userList.length <= 0
                                ?
                                <tr>
                                    <td colSpan="7">No Data Available</td>
                                </tr>
                                :
                                <></>
                        }
                    </tbody>
                </Table>
                <PopupComponent title="User Details" PopupFor="User" Size="lg">
                    <UserDetailComponent></UserDetailComponent>
                </PopupComponent>
            </ContainerComponent>
        </UserContext.Provider>
    )
}

export default UserListComponent
