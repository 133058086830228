import React from 'react'
import DataFilterListComponent from '../components/DataFilter/DataFilterListComponent'

function DataFilterPage() {
    return (
        <DataFilterListComponent></DataFilterListComponent>
    )
}

export default DataFilterPage
