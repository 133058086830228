import axios from 'axios';
import * as CommonVariables from '../utils/CommonVariables';
import { trackPromise } from "react-promise-tracker";

export const ValidateUser = async (user) => {

    var result = await axios.post(CommonVariables.API_DOMAIN + "Login/ValidateUserOTP",user, CommonVariables.API_HEADER)
    .then(response => response);
    return result;
}

export const GetOTPByMobileNo = async (mobileNo) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `Login/GetOTPByMobileNo?mobileNo=${mobileNo}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

