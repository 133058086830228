import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { store } from '../store/store';

const LoginLayoutRoute = ({ component: Component, ...rest }) => {

    const loggedInUser = store.getState();
    const isAuthenticated = loggedInUser.isAuthenticated;

    return (
        <Route {...rest} render={matchProps => (
            //isAuthenticated === true ? (
            //    <Redirect to="/home"></Redirect>
            //) : (
            <Component {...matchProps} />
            //)
            //)
            //} 
        )}
        />
    )
};

export default LoginLayoutRoute;