import axios from "axios";
import * as CommonVariable from '../utils/CommonVariables'
import { trackPromise } from "react-promise-tracker";

export const GetDataEvent = () => {
    const response = trackPromise(axios.get(CommonVariable.API_DOMAIN + 'EventService/GetAllEventService', CommonVariable.API_HEADER))
    .then(response => response);
    return response;
}

export const GetDataEventById = (id) => {
    const response = trackPromise(axios.get(CommonVariable.API_DOMAIN + `EventService/GetEventServiceById?Id=${id}`, CommonVariable.API_HEADER)).then(response => response);
    return response;
}

export const GetDataFilterCriteriaById = (id) => {
    const response = axios.get(CommonVariable.API_DOMAIN + `EventService/GetFilterCriterById?id=${id}`, CommonVariable.API_HEADER).then(response => response);
    return response;
}

export const ActivateDeactivateDataEvent = (id, isActive) => {
    const response = trackPromise(axios.post(CommonVariable.API_DOMAIN + `EventService/ActivateDeactivateEventService?Event_Id=${id}&isActive=${isActive}`, CommonVariable.API_HEADER)).then(response => response);
    return response;
}

export const SaveDataEvent = (data) => {
    const response = trackPromise(axios.post(CommonVariable.API_DOMAIN + `EventService/SaveEventService`, JSON.stringify(data), CommonVariable.API_HEADER)).then(response => response);
    return response;
}

export const UpdateDataEvent = (data) => {
    const response = trackPromise(axios.post(CommonVariable.API_DOMAIN + `EventService/UpdateEventService`, JSON.stringify(data), CommonVariable.API_HEADER)).then(response => response);
    return response;
}

export const GetDBConnectionList = () => {
    const response = trackPromise(axios.get(CommonVariable.API_DOMAIN + 'EventService/GetAllDBConnecctionList', CommonVariable.API_HEADER))
    .then(response => response);
    return response;
}

export const RunEventAsync = (eventName) => {
    const response = trackPromise(axios.get(CommonVariable.API_DOMAIN + `EventService/RunEvent?event_name=${eventName}`, CommonVariable.API_HEADER))
    .then(response => response);
    return response;
}
export const GetEventReportHistoryList = () => {
    const response = trackPromise(axios.get(CommonVariable.API_DOMAIN + `EventService/GetEventReportHistoryList`, CommonVariable.API_HEADER))
    .then(response => response);
    return response;
}