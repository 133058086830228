
import React from 'react';

const TextField = ({ label, register, rules, placeholder, errors, name, type }) => {

    return (
        <div>
            <label className="requiredfeild">{label}</label>
            <input placeholder={placeholder} type={type} className={type != "checkbox" ? "form-control" : null} {...register(name, { ...rules })} />
            {errors[name] && errors[name].type === 'required' && <p style={{ color: 'red' }} >Required</p>}
            {errors[name] && errors[name].type === 'maxLength' && <p style={{ color: 'red' }} >{label} should be have maximum of {rules.maxLength} characters</p>}
            {errors[name] && errors[name].type === 'minLength' && <p style={{ color: 'red' }} >{label} should be contain atleast {rules.minLength} characters</p>}
            {errors[name] && errors[name].type === 'max' && <p style={{ color: 'red' }} >{label} should be have maximum of {rules.max} characters</p>}
            {errors[name] && errors[name].type === 'min' && <p style={{ color: 'red' }} >{label} should be contain atleast {rules.min} characters</p>}
            {errors[name] && errors[name].type === 'pattern' && <p style={{ color: 'red' }} >Invalid {label}</p>}
        </div>
    );
};
export default TextField;