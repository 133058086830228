import React from 'react'
import { BrowserRouter as Route, Router, Link } from 'react-router-dom'
import DashboardComponent from '../components/Dashboard/DashboardComponent';

export default function DashboardPage() {
    return (
        <div className="container-fluid">
            <DashboardComponent></DashboardComponent>
        </div>
    )
}
