import { store } from "../store/store"

export const set_user_details = (data) => {
    return {
        type: "user_info",
        user: data.Data
    }
}

export const reset_user_details = (data) => {
    return {
        type: "logout",
        user: data
    }
}

export const thunk_user_info_creator = (userInfo) => {
    return function (dispatch, getState) {
       dispatch(set_user_details(userInfo));
    }
}

export const thunk_reset_user_info_creator = (userInfo) => {
    return function (dispatch, getState) {
       dispatch(reset_user_details(userInfo));
    }
}