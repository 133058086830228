import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "../src/style.scss";
import { store, persistor } from './store/store';
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';

//Add Authorization Header in every HTTP request
axios.interceptors.request.use(function (config) {  
  const token = store.getState();  
  if (token.isAuthenticated)
    config.headers.Authorization = `Bearer ${token.userData.AccessToken}`;
  else
    config.headers.Authorization = null;

  return config;
});
//End

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
