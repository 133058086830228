import React, { useContext, useEffect, useState } from 'react'
import { Table, Alert } from 'react-bootstrap';
import ContainerComponent from '../Common/ContainerComponent';
import PopupComponent from '../Common/PopupComponent'
import { DataFilterContext } from './DataFilterContext'
import * as DataEventService from '../../services/DataFilterService'
import { FaCheckCircle, FaEdit, FaRecycle, FaTimesCircle, FaRegTrashAlt, FaLocationArrow, FaFilter } from 'react-icons/fa';
import DataFilterDetailComponent from './DataFilterDetailComponent';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom'
import LoadingSpinnerComponent from '../../utils/LoadingSpinnerComponent';

function DataFilterListComponent() {
    const [isModelOpen, setModel] = useState(false);
    const [dataEventList, SetDataEventList] = useState([]);
    const [selectedDataEventId, setDataEventId] = useState(0);
    const [error, setError] = useState("");
    const history = useHistory();
    

    const toggle = () => { setModel(!isModelOpen) }

    useEffect(() => {
        setError('');
        if (!isModelOpen) {
            DataEventService.GetDataEvent().then(data => {
                    if (data.data.IsSuccess) {
                        SetDataEventList(data.data.Data);
                    }else {
                        SetDataEventList([]);
                        setError(data.data.ResponseMessage);
                        // toast.error(data.data.ResponseMessage);
                    }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else
                {setError(error.message);
                toast.error(error.message);}
            });
        }
    }, [isModelOpen])

    const addNewEvent = () => {
        setDataEventId(0);
        toggle();
    }

    const editDataEvent = (id) => {
        setDataEventId(id);
        toggle();
    }
    const runEventAsync = (eventName) => {
        setError('');
        DataEventService.RunEventAsync(eventName).then(data => {
            if (data.data.IsSuccess) {
                toast.success(data.data.ResponseMessage);
            }
            else{
                toast.error(data.data.ResponseMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else
            {setError(error.message);
            toast.error(error.message);}
        })
    }

    const deleteEvent = (id, isActive) => {
        setError('');
        DataEventService.ActivateDeactivateDataEvent(id, !isActive).then(data => {
            if (data.data.IsSuccess) {
                toast.success(data.data.ResponseMessage);
                //Reload Table
                DataEventService.GetDataEvent().then(data => {
                    if (data.data.IsSuccess) {
                        SetDataEventList(data.data.Data);
                    }
                    else{
                        SetDataEventList([])
                        // setError(data.data.ResponseMessage)
                        // toast.error(data.data.ResponseMessage)
                    }
                })
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else
            {setError(error.message);
            toast.error(error.message);}
        })
    }

    return (
        <DataFilterContext.Provider value={{ isModelOpen, toggle, selectedDataEventId }}>
            <ContainerComponent title="Scheduler Event" Icon={<FaFilter/>} AddNewAction={addNewEvent}>                
                {error ? (<Alert color="danger">
                    {error}
                </Alert>) : <></>}
                <LoadingSpinnerComponent></LoadingSpinnerComponent>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <Table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Event Name</th>
                            <th>Report Name</th>
                            <th>Event Type</th>
                            <th>Event Method</th>
                            <th style={{width: "12em"}} >Scheduled Type</th>
                            <th>Active</th>
                            <th style={{width: "6.5em"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataEventList.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.Id}</td>
                                    <td>{item.Event_Name}</td>
                                    <td>{item.Report_Display_Name}</td>
                                    <td>{item.Event_Type}</td>
                                    <td>{item.Event_Method}</td>
                                    <td>{item.Scheduled_Type == "Daily" ? <> Daily {item.Scheduled_Hour} : {item.Scheduled_Minute} {parseInt(item.Scheduled_Hour)> 11 ? 'PM' :'AM'} </>: ""} 
                                    {item.Scheduled_Type == "Every Hour" ? <> Every {item.Scheduled_Hour} hour  </>:  ""}
                                    {item.Scheduled_Type == "Every Minute" ? <> Every {item.Scheduled_Minute} min </>:  ""}
                                    {item.Scheduled_Type == "Custom Expression" ? <> {item.Scheduled_Expression} </>:  ""}
                                    </td>
                                    <td>
                                        {
                                            item.IsActive ? <FaCheckCircle style={{ color: 'green' }} /> : <FaTimesCircle style={{ color: 'red' }} />
                                        }
                                    </td>
                                    <td>
                                    {
                                            item.IsActive ?
                                         <FaLocationArrow style={{ color: 'green',cursor:"pointer" }} onClick={() => runEventAsync(item.Event_Name)}></FaLocationArrow>:""}
                                         {' '}
                                        <FaEdit style={{ cursor: "pointer" }} onClick={() => editDataEvent(item.Id)}></FaEdit>
                                        {' '}
                                        {
                                            item.IsActive ? <FaRegTrashAlt style={{ color: 'green',cursor:"pointer" }} onClick={() => { deleteEvent(item.Id, item.IsActive) }} /> : <FaRegTrashAlt style={{ color: 'red' }} onClick={() => { deleteEvent(item.Id, item.IsActive) }} />
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                         {
                            dataEventList.length <= 0
                                ?
                                <tr>
                                    <td colSpan="7">No Data Available</td>
                                </tr>
                                :
                                <></>
                        }
                    </tbody>
                </Table>
            </ContainerComponent>
            <PopupComponent title="Scheduler Event Details" PopupFor="DataEvent" Size="lg">
                <DataFilterDetailComponent></DataFilterDetailComponent>
            </PopupComponent>
        </DataFilterContext.Provider>
    )
}

export default DataFilterListComponent