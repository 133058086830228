import Multiselect from 'multiselect-react-dropdown';
import React, { useState, useEffect, useContext } from 'react'
import { useForm } from 'react-hook-form';
import { Form, Label, Input, FormGroup, Button, Alert } from 'reactstrap';
import { store } from '../../store/store';
import { ContextFactory } from '../../utils/ContextFactory';
import * as UserService from '../../services/UserService';
import LoadingSpinnerComponent from '../../utils/LoadingSpinnerComponent';
import { Col, Row } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import TextField from '../Common/TextFieldComponent';
import useLoaderState from '../../utils/useLoaderState'


const UserDetailComponent = (props) => {
    const { register, handleSubmit, reset, clearErrors, getValues, userData, formState: { errors } } = useForm({
        mode: "onBlur" // "onChange"
    });
    const [error, setError] = useState('');
    const [roleData, setRoleList] = useState([]);
    const [userSCMappingData, setUserSCMapping] = useState([]);
    const [serviceCenterData, setServiceCenterList] = useState([]);
    const [selectedServiceCenterData, setSelectedServiceCenterList] = useState([]);
    const { toggle, selectedUserId } = useContext(ContextFactory("User"));
    const history = useHistory();
    const userInfo = store.getState();
    const [IsLoading] = useLoaderState();
    // const sortedByName = projects.sort(sortIt('name'));
    useEffect(() => {
        GetRole();
        GetServiceCenter();
        if (selectedUserId > 0) {
            UserService.GetUserById(selectedUserId).then(response => {
                if (response.data.IsSuccess) {
                    console.log(response.data.ResponseData)
                    reset({
                        First_Name: response.data.ResponseData.First_Name,
                        Last_Name: response.data.ResponseData.Last_Name,
                        Password: response.data.ResponseData.Password,
                        Confirm_Password: response.data.ResponseData.Password,
                        Email_Id: response.data.ResponseData.Email_Id,
                        IsActive: response.data.ResponseData.IsActive,
                        Role_Id: response.data.ResponseData.Role_Id,
                        Role_Name: response.data.ResponseData.Role_Name,
                        Mobile_No: response.data.ResponseData.Mobile_No,
                    });
                    if (response.data.ResponseData.ServiceCenter != "") {
                        setSelectedServiceCenterList(response.data.ResponseData.ServiceCenter)
                    }
                }
                else {
                    setError(response.data.errorMessage);
                    toast.error(response.data.errorMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    toast.error(error.message);
                }
            });
        }
    }, []);

    const SaveUser = (userData) => {
        setError('');
        userData.ServiceCenter = selectedServiceCenterData;
        UserService.SaveUser(userData).then(data => {
            if (data.data.IsSuccess) {
                toast.success("User " + data.data.UiMessage);
                toggle();
            } else {
                setError(data.data.errorMessage);
                toast.error(data.data.errorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const UpdateUser = (userData) => {
        setError('');
        //Set Service Center List
        userData.ServiceCenter = selectedServiceCenterData;

        UserService.UpdateUser(userData).then(data => {
            if (data.data.IsSuccess) {
                toast.success("User " + data.data.UiMessage);
                toggle();
            } else {
                setError(data.data.errorMessage);
                toast.error(data.data.errorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });
    }

    const updateServiceCenterList = (data) => {
        setSelectedServiceCenterList(data);
    }

    const allowonlynumber = (e) => {
        var a = [];
        var k = e.which;

        for (var i = 48; i < 58; i++)
            a.push(i);

        if (!(a.indexOf(k) >= 0))
            e.preventDefault();
    };


    const GetRole = () => {
        setError('');
        UserService.GetRoleList().then(data => {
            if (data.data.IsSuccess) {
                setRoleList(data.data.ResponseData);
            } else {
                setError(data.data.errorMessage);
                toast.error(data.data.errorMessage);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            setError(error.message);
            toast.error(error.message);
        });
    }

    const GetServiceCenter = () => {
        setError('');
        UserService.GetServiceCenterList().then(data => {
            if (data.data.IsSuccess) {
                // const sortedByName = projects;
                setServiceCenterList(data.data.Data.sort(sortIt('Name')));
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
        });
    }
    const sortIt = sortBy => (a, b) => {
        if (a[sortBy] > b[sortBy]) {
           return 1;
        } else if (a[sortBy] < b[sortBy]) {
           return -1;
        }
        return 0;
     }
    return (

        <Form autoComplete="off">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <LoadingSpinnerComponent></LoadingSpinnerComponent>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <input type="hidden" {...register("User_Id")} value={selectedUserId}></input>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <TextField
                            label="First Name"
                            placeholder="First Name"
                            register={register}
                            name="First_Name"
                            errors={errors}
                            rules={{ minLength: 3, maxLength: 30, required: true, pattern: /[A-Za-z\s]$/i }}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <TextField
                            label="Last Name"
                            placeholder="Last Name"
                            register={register}
                            name="Last_Name"
                            errors={errors}
                            rules={{ minLength: 0, maxLength: 30, required: true, pattern: /[A-Za-z\s]$/i }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label>Service Center</Label>
                        <Multiselect  avoidHighlightFirstOption="true" options={serviceCenterData} selectedValues={selectedServiceCenterData} onSelect={updateServiceCenterList} onRemove={updateServiceCenterList} displayValue="Name">

                        </Multiselect>
                    </FormGroup>
                </Col>

                <Col md="6">
                    <FormGroup >
                        <Label className="requiredfeild">Email Id</Label>
                        <input type="text" className="form-control" placeholder="Email Id" {...register("Email_Id", {
                            required: "Required",
                            pattern: {
                                value: /^\w+([\.-]?\w+)@hicare.in$/i,
                                message: "Email Id should end with @hicare.in"

                            }
                        })} />
                        {errors.Email_Id && <p style={{ color: 'red' }}>{errors.Email_Id.message}</p>}
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label className="requiredfeild">Password</Label>
                        <input type="text" className="form-control" placeholder="Password"
                            {...register("Password", { required: "Required" })}
                        />
                        {errors.Password && <p style={{ color: 'red' }}>{errors.Password.message}</p>}
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label className="requiredfeild" >Confirm Password</Label>
                        <input type="password" className="form-control" placeholder="Confirm Password"
                            {...register("Confirm_Password", {
                                required: "Required",
                                validate: {
                                    matchPreviousPassword: (value) => {
                                        const { Password } = getValues();
                                        return Password === value || "Password should match";
                                    }
                                }
                            })}
                        />
                        {errors.Confirm_Password && <p style={{ color: 'red' }}>{errors.Confirm_Password.message}</p>}
                    </FormGroup>
                </Col>
            </Row>


            <Row>
                <Col md="6">
                    <FormGroup >
                        <Label className="requiredfeild">Mobile No</Label>
                        <input type="text" className="form-control" onKeyPress={(e) => allowonlynumber(e)} placeholder="Mobile No" {...register("Mobile_No", {
                            required: "Required",
                            pattern: {
                                value: /^[1-9][0-9]{9}$/i,
                                message: "Invalid Mobile No."
                            },
                            maxLength: "10"
                        })} />
                        {errors.Mobile_No && errors.Mobile_No.type === "maxLength" && <p style={{ color: 'red' }}>{"Mobile No should not be greater or less than 10"}</p>}
                        {errors.Mobile_No && <p style={{ color: 'red' }}>{errors.Mobile_No.message}</p>}
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label className="requiredfeild">User Role</Label>
                        <select name="select" className="form-control" {...register("Role_Id", { required: "Required" })}>
                            <option value="">Select</option>
                            {
                                roleData.map((r) => {
                                    return <option key={r.Role_Id} value={r.Role_Id}>{r.Role_Name}</option>
                                })
                            }
                        </select>
                        {errors.Role_Id && <p style={{ color: 'red' }}>{errors.Role_Id.message}</p>}
                    </FormGroup>

                </Col>
                <Col md="6">
                    {
                        selectedUserId > 0 ?
                            <FormGroup>
                                <Label>Is Active </Label> &nbsp;&nbsp;
                                <input type="checkbox" {...register("IsActive")} />
                                {errors.IsActive && <p style={{ color: 'red' }}>{errors.IsActive.message}</p>}
                            </FormGroup>
                            : ""
                    }
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <FormGroup className="float-right">

                        {selectedUserId > 0 ?
                            <button type="submit" disabled={IsLoading} className="btn btn-primary" onClick={handleSubmit(UpdateUser)}>Update</button> :
                            <button type="submit" disabled={IsLoading} className="btn btn-primary" onClick={handleSubmit(SaveUser)}>Save</button>
                        }
                        {' '}<button type="button" disabled={IsLoading} className="btn btn-secondary" onClick={() => toggle()}>Cancel</button>

                    </FormGroup>

                </Col>
            </Row>
        </Form>
    );
}

export default UserDetailComponent;