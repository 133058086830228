import axios from 'axios';
import * as CommonVariables from '../utils/CommonVariables';
import { store } from '../store/store';
import { trackPromise } from 'react-promise-tracker';

export const SaveUser = async (userData) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'User/SaveUser', JSON.stringify(userData), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


export const UpdateUser = async (userData) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'User/UpdateUser', userData, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetUserById = async (userId) => {
    
    const response = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `User/GetUserById?Id=${userId}`,CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActivateDeactiveUser = async (User_Id,IsActive) => {
    
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `User/ActivateDeactivateUser?Id=${User_Id}&IsActive=${IsActive}`,CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetAllUserList = async () => {

    const response = await trackPromise(axios.get(CommonVariables.API_DOMAIN + "User/GetUserList", CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetRoleList = async () => {

    const response = await axios.get(CommonVariables.API_DOMAIN + `Role/GetAllRole`,  CommonVariables.API_HEADER)
        .then(response => response);
    return response;

}

export const GetCityList = async () => {

    const response = await axios.get(CommonVariables.API_DOMAIN + "City/GetAllCity",CommonVariables.API_HEADER)
        .then(response => response);
    return response;

}

export const GetServiceCenterList = async () => {

    const response = await axios.get("http://apps.hicare.in/api/api/ServiceCenter/GetServiceCenterList",CommonVariables.API_HEADER)
        .then(response => response);
    return response;

}