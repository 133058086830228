import React, { Component } from 'react'
import { Route, Redirect } from 'react-router'
import LeftMenuComponent from '../components/Menu/LeftMenuComponent';
import TopMenuComponent from '../components/Menu/TopMenuComponent';
import { store } from '../store/store';

const AdminLayoutRoute = ({ component: Component, ...rest }) => {
    const loggedInUser = store.getState();
    const isAuthenticated = loggedInUser.isAuthenticated;
    return (
        <Route {...rest} render={matchProps => (
            isAuthenticated === true ? (
                <div id="wrapper">
                    <LeftMenuComponent></LeftMenuComponent>
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <TopMenuComponent></TopMenuComponent>
                            <Component {...matchProps} />
                        </div>
                    </div>
                </div>) :
                (<Redirect to="/"></Redirect>)
        )} />
    )
};

export default AdminLayoutRoute;
