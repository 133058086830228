import React from 'react'
import UserListComponent from '../components/User/UserListComponent'

function UserPage() {
    return (
        <UserListComponent></UserListComponent>
    )
}

export default UserPage
