import { Button } from 'bootstrap'
import React, { useContext } from 'react'
import { FaPlus } from 'react-icons/fa'
import { ContextFactory } from '../../utils/ContextFactory';

function ContainerComponent(props) {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card shadow mb-4">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-9">
                                    <h5 className="w-75 text-success">{props.Icon} {props.title}</h5>
                                </div>
                                {
                                    props.ShowAction == "false" ?(<></>):
                                <div className="col-md-3 float-right" style={{ paddingRight: "2px" }}>
                                    <button className="btn btn-success" style={{ whiteSpace: 'nowrap' }} onClick={() => props.AddNewAction()} color="success"><FaPlus /> New {props.title}</button>
                                </div>}
                            </div>
                        </div>
                        <div className="card-body">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContainerComponent
