import React, { useEffect, useContext, useState } from 'react'
import { ContextFactory } from '../../utils/ContextFactory'
import * as DataEventService from '../../services/DataFilterService'
import { useForm } from 'react-hook-form';
import { Alert, FormGroup, Label, Form, Row, Col } from 'reactstrap';
import * as CommonVariables from '../../utils/CommonVariables';
import { FaPlus, FaTrashAlt } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import TextField from '../Common/TextFieldComponent';
import useLoaderState from '../../utils/useLoaderState';

function DataFilterDetailComponent() {
    const { register, watch, reset, setValue, getValues, formState: { errors }, handleSubmit } = useForm({ mode: "onChange" });
    const { toggle, selectedDataEventId } = useContext(ContextFactory("DataEvent"));
    const [error, setError] = useState("");
    const [EventParam, setEventParam] = useState([]);
    const history = useHistory();
    const [IsLoading] = useLoaderState();
    const [eventMethodData, setEventMethod] = useState([]);
    const [DBConnection, setDBConnection] = useState([]);
    const [showDBConnectionOption, setshowDBConnectionOption] = useState(false);
    const [showParameterOption, setShowParameterOption] = useState(true);
    const eventType = watch("Event_Type");
    const eventMethod = watch("Event_Method");
    const [ShowMinuteSelection, setShowMinuteSelection] = useState(false);
    const [ShowCustomSelection, setShowCustomSelection] = useState(false);
    const [ShowHourSelection, setShowHourSelection] = useState(false);

    useEffect(() => {
        if (parseInt(selectedDataEventId)) {
            setError('');
            DataEventService.GetDataEventById(selectedDataEventId).then(data => {
                if (data.data.IsSuccess) {
                    loadEventType(data.data.Data.Event_Type);
                    loadEventMethod(data.data.Data.Event_Method);
                    showHourMinuteSelection(data.data.Data.Scheduled_Type);
                    reset(data.data.Data);
                    if (data.data.Data.Scheduled_Type == "Custom Expression")
                        setValue('Scheduled_CustomExpression', data.data.Data.Scheduled_Expression)
                    //Load Filter Criteria
                    DataEventService.GetDataFilterCriteriaById(selectedDataEventId).then(criteria => {
                        if (criteria.data.IsSuccess)
                            setEventParam(criteria.data.Data);
                    })
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            })
        }
        GetDBConnectionList()
    }, [])

    const GetDBConnectionList = () => {
        DataEventService.GetDBConnectionList().then(data => {
            if (data.data.IsSuccess) {
                setDBConnection(data.data.Data);
            } else {
                setDBConnection([]);
            }
        }).catch(error => {
            if (error.message.includes("401")) {
                toast.error("Your session is expired, Redirecting to Login page")
                history.push('')
            }
            else {
                setError(error.message);
                toast.error(error.message);
            }
        });

    }


    const formSubmit = (value) => {
        setError('');
        value.EventCriteriaList = EventParam;
        value.IsActive = true;
        if (EventParam.filter(o => o.Key == undefined || o.Key == null || o.Key == '').length > 0) {
            toast.error("Please add Parameters");
            return false;
        }
        if (parseInt(selectedDataEventId) > 0) {
            DataEventService.UpdateDataEvent(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Data Event " + data.data.ResponseMessage);
                    toggle();
                } else {
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            })
        } else {
            DataEventService.SaveDataEvent(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Data Event " + data.data.ResponseMessage);
                    toggle();
                } else {
                    setError(data.data.ResponseMessage);
                    toast.error(data.data.ResponseMessage);
                }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else {
                    setError(error.message);
                    toast.error(error.message);
                }
            })
        }
    }

    const AddEventParam = (e) => {
        setEventParam(param => {
            return [...param, { Id: EventParam.length + 1, Event_Id: parseInt(selectedDataEventId), Key: "", Value: "", Type: "" }]
        })
    }

    const handleParamValue = (e, index) => {
        var tempParam = [...EventParam];
        tempParam[index].Value = e.target.value;
        setEventParam(tempParam);
    }
    const handleParamType = (e, index) => {
        var tempParam = [...EventParam];
        tempParam[index].Type = e.target.value;
        setEventParam(tempParam);
    }

    const handleParamKey = (e, index) => {
        var tempParam = [...EventParam];
        tempParam[index].Key = e.target.value;
        setEventParam(tempParam);
    }

    const deleteParam = (index, EventId) => {
        setEventParam(param => {
            return param.filter(item => item.Id != EventId);
        })
    }

    const handleClick = (e) => {
        e.preventDefault();
        window.open("https://www.freeformatter.com/cron-expression-generator-quartz.html#cronconverttotext");
    };

    const loadEventType = function (eventType) {
        setError('');
        if (eventType != '' && eventType == 'API') {
            setEventMethod(CommonVariables.API_Event_Method)
            setshowDBConnectionOption(false)
        }
        else {
            setEventMethod(CommonVariables.DB_Event_Method)
            setshowDBConnectionOption(true)
        }
    }

    const loadEventMethod = function (eventmethod) {
        setError('');
        if (eventmethod != '' && eventmethod == 'Query') {
            setShowParameterOption(false);
        }
        else {
            setShowParameterOption(true);
        }
    }
    const showHourMinuteSelection = function (scheduledType) {
        setError('');
        if (scheduledType == "Every Minute") {
            setShowMinuteSelection(true)
            setShowHourSelection(false)
            setShowCustomSelection(false)
        }
        else if (scheduledType == "Every Hour") {
            setShowMinuteSelection(false)
            setShowHourSelection(true)
            setShowCustomSelection(false)
        }
        else if (scheduledType == "Daily") {
            setShowMinuteSelection(true)
            setShowHourSelection(true)
            setShowCustomSelection(false)
        }
        else if (scheduledType == "Custom Expression") {
            setShowMinuteSelection(false)
            setShowHourSelection(false)
            setShowCustomSelection(true)
        }
        else {
            setShowMinuteSelection(false)
            setShowHourSelection(false)
            setShowCustomSelection(false)
        }
    }

    return (
        <Form autoComplete="off">
            {error ? (<Alert color="danger">
                {error}
            </Alert>) : <></>}
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <input type="hidden" {...register("Id")} value={selectedDataEventId}></input>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <TextField
                            label="Event Name"
                            placeholder="Event Name"
                            register={register}
                            name="Event_Name"
                            errors={errors}
                            rules={{ minLength: 3, maxLength: 50, required: true, pattern: /[A-Za-z0-9]$/i }}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <TextField
                            label="Report Display Name"
                            placeholder="Report Display Name"
                            register={register}
                            name="Report_Display_Name"
                            errors={errors}
                            rules={{ minLength: 3, maxLength: 50, required: true }}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>


                <Col md="4">
                    <FormGroup>
                        <Label className="requiredfeild" >Event Type</Label>
                        <select className="form-control" {...register("Event_Type", { required: "Required" })}
                            onChange={(e) => {
                                loadEventType(e.target.value)
                            }}>
                            <option value="">Select</option>
                            {CommonVariables.Event_Type.map((data, index) => {
                                return <option key={data.Value} value={data.Value}>{data.Key}</option>
                            })}
                        </select>
                        {errors.Event_Type && <p style={{ color: "red" }}>{errors.Event_Type.message}</p>}
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label className="requiredfeild" >Event Method</Label>
                        <select className="form-control" {...register("Event_Method", { required: "Required" })}
                            onChange={(e) => {
                                loadEventMethod(e.target.value)
                            }}>
                            <option value="">Select</option>
                            {eventMethodData.map((data, index) => {
                                return <option key={data.Value} value={data.Value}>{data.Key}</option>
                            })}
                        </select>
                        {errors.Event_Method && <p style={{ color: "red" }}>{errors.Event_Method.message}</p>}
                    </FormGroup>
                </Col>
                {
                    (showDBConnectionOption) ?
                        <Col md="4">
                            <FormGroup>
                                <Label className="requiredfeild" >Connection Name</Label>
                                <select className="form-control" {...register("DB_Connection_Id", { required: "Required" })}>
                                    <option value="">Select</option>
                                    {DBConnection.map((data, index) => {
                                        return <option key={data.DB_Name} value={data.Id}>{data.DB_Name}</option>
                                    })}
                                </select>
                                {errors.Event_Method && <p style={{ color: "red" }}>{errors.Event_Method.message}</p>}
                            </FormGroup>
                        </Col> : ""}
            </Row>
            <Row>
                <Col md="12">
                    <FormGroup>
                        <TextField
                            label="Event URL"
                            placeholder="Event URL"
                            register={register}
                            name="Event_URL"
                            errors={errors}
                            rules={{ required: true }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md="8">
                    <FormGroup>
                        <TextField
                            label="Notify To"
                            placeholder="Notify To"
                            register={register}
                            name="Notify_To"
                            errors={errors}
                            rules={{ minLength: 3, required: true }}
                        />
                    </FormGroup>
                </Col>

                <Col md="4">
                    <FormGroup >
                        <Label > Is Send Notification </Label>
                        <input style={{ right: "85px", position: "absolute", top: "5px" }} type="checkbox" {...register("IsSendNotification")}></input>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    <FormGroup>
                        <Label className="requiredfeild" >Scheduled Type</Label>
                        <select className="form-control" {...register("Scheduled_Type", { required: "Required" })}
                            onChange={(e) => {
                                showHourMinuteSelection(e.target.value)
                            }}>
                            <option value="">Select</option>
                            {CommonVariables.Scheduled_Type.map((data, index) => {
                                return <option key={data.Value} value={data.Value}>{data.Key}</option>
                            })}
                        </select>
                        {errors.Event_Type && <p style={{ color: "red" }}>{errors.Event_Type.message}</p>}
                    </FormGroup>
                </Col>
                {
                    (ShowHourSelection) ?
                        <Col md="4">
                            <FormGroup>
                                <TextField
                                    label="Scheduled Hour"
                                    placeholder="Enter Hour"
                                    register={register}
                                    name="Scheduled_Hour"
                                    errors={errors}
                                    rules={{ min: 0, max: 23, required: true, pattern: /[0-9]$/i }}
                                />
                            </FormGroup>
                        </Col> : ""
                }
                {
                    (ShowMinuteSelection) ?
                        <Col md="4">
                            <FormGroup>
                                <TextField
                                    label="Scheduled Minute"
                                    placeholder="Enter Minute"
                                    register={register}
                                    name="Scheduled_Minute"
                                    errors={errors}
                                    rules={{ min: 0, max: 59, required: true, pattern: /[0-9]$/i }}
                                />
                            </FormGroup>
                        </Col> : ""
                }
                {
                    (ShowCustomSelection) ?
                        <Col md="6">
                            <FormGroup>
                                <TextField
                                    label="Scheduled Custom Expression"
                                    placeholder="Scheduled Custom Expression"
                                    register={register}
                                    name="Scheduled_CustomExpression"
                                    errors={errors}
                                />
                            </FormGroup>
                        </Col> : ""
                }
            </Row>
            <Row>
                <Col md="4">
                </Col>
                {
                    (ShowHourSelection) ? <Col md="4">
                        <p style={{ color: "red", fontSize: "12px" }}>
                            Select Hour between 0 to 23
                        </p>
                    </Col> : ""}
                {
                    (ShowMinuteSelection) ?
                        <Col md="4">
                            <p style={{ color: "red", fontSize: "12px" }}>
                                Select Minute between 0 to 59
                            </p>
                        </Col> : ""}
                {
                    (ShowCustomSelection) ?
                        <Col md="8">
                            <p style={{ color: "red", fontSize: "12px" }}>
                                For every day 9am to 12pm - after 2 hours expression (0 9-12/2 * * * * )
                            </p>
                        </Col> : ""}
            </Row>

            {
                (showParameterOption) ?
                    <Row>
                        <Col md="12">
                            <div className="row">
                                <div className="card shadow mb-4">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-11">
                                                <h6 className="w-75">Parameters</h6>
                                            </div>
                                            <div className="col-md-1 float-right" style={{ paddingRight: "2px" }}>
                                                <button type="button" className="btn btn-success btn-sm" onClick={(e) => AddEventParam(e)} color="success"><FaPlus /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        {
                                            EventParam.map((data, index) => {
                                                return (
                                                    <div className="row" style={{ marginBottom: "2px" }} key={index}>
                                                        <div className="col-md-2">
                                                            <Label className="requiredfeild" >Parameter {index + 1}</Label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input type="text" placeholder="Key" className="form-control" value={data.Key} onChange={(e) => handleParamKey(e, index)}></input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input type="text" placeholder="Value" className="form-control" value={data.Value} onChange={(e) => handleParamValue(e, index)}></input>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <select className="form-control" value={data.Type} onChange={(e) => handleParamType(e, index)}>
                                                                <option value="">Select</option>
                                                                {CommonVariables.DataType.map((data, index) => {
                                                                    return <option key={data.Type} value={data.Type}>{data.Type}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <FaTrashAlt onClick={() => deleteParam(index, data.Id)} />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    : ""
            }

            <FormGroup className="float-right">
                <button type="submit" className="btn btn-primary" disabled={IsLoading} onClick={handleSubmit(formSubmit)}>{selectedDataEventId > 0 ? 'Update' : 'Save'}</button>
                {' '}
                <button type="button" className="btn btn-secondary" onClick={() => { toggle() }}>Cancel</button>
            </FormGroup>
        </Form>
    )
}

export default DataFilterDetailComponent
