import React, { useContext, useEffect, useState } from 'react'
import { Table, Alert } from 'react-bootstrap';
import ContainerComponent from '../Common/ContainerComponent';
import * as DataEventService from '../../services/DataFilterService'
import { FaCheckCircle, FaEdit, FaInstalod, FaTimesCircle, FaRegTrashAlt, FaLocationArrow, FaFilter } from 'react-icons/fa';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom'
import LoadingSpinnerComponent from '../../utils/LoadingSpinnerComponent';

function SchedulerHistoryReportList() {
    const [isModelOpen, setModel] = useState(false);
    const [dataEventList, SetDataEventList] = useState([]);
    const [error, setError] = useState("");
    const history = useHistory();
    

    const toggle = () => { setModel(!isModelOpen) }

    useEffect(() => {
        setError('');
       
            DataEventService.GetEventReportHistoryList().then(data => {
                    if (data.data.IsSuccess) {
                        SetDataEventList(data.data.Data);
                    }else {
                        SetDataEventList([]);
                        setError(data.data.ResponseMessage);
                        // toast.error(data.data.ResponseMessage);
                    }
            }).catch(error => {
                if (error.message.includes("401")) {
                    toast.error("Your session is expired, Redirecting to Login page")
                    history.push('')
                }
                else
                {setError(error.message);
                toast.error(error.message);}
            });
    }, [])

   
    return (
            <ContainerComponent title="Event History Report" Icon={<FaInstalod/>} ShowAction="false" >                
                {error ? (<Alert color="danger">
                    {error}
                </Alert>) : <></>}
                <LoadingSpinnerComponent></LoadingSpinnerComponent>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <Table>
                    <thead>
                        <tr>
                            <th style={{width: "2%"}}>Id</th>
                            <th style={{width: "8%"}}>Event Name</th>
                            <th style={{width: "15%"}}>Event URL</th>
                            <th style={{width: "10%"}}>API Start-End Time</th>
                            <th style={{width: "65%"}}>API Response</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataEventList.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.Id}</td>
                                    <td style={{ wordBreak: "break-all" }}>{item.Event_Name}</td>
                                    <td style={{ wordBreak: "break-all" }}>{item.Event_URL}</td>
                                    <td style={{ wordBreak: "break-all" }}>{item.Display_Api_InTime} - {item.Display_Api_OutTime}</td>
                                    <td style={{ wordBreak: "break-all" }}>{item.Response}</td>
                                </tr>
                            )
                        })}
                         {
                            dataEventList.length <= 0
                                ?
                                <tr>
                                    <td colSpan="7">No Data Available</td>
                                </tr>
                                :
                                <></>
                        }
                    </tbody>
                </Table>
            </ContainerComponent>
    )
}

export default SchedulerHistoryReportList